@import '../../styles/propertySets.css';

.root {
    /* Layout */
    display: flex;
    flex-direction: column;

    /* Remove link's hover effect */
    &:hover {
        text-decoration: none;
    }
}

.aspectRatioWrapper {
    transition: var(--transitionStyleButton);
    background: var(--matterColorNegative); /* Loading BG color */
    border-radius: 4px;

    @media (--viewportMedium) {
        &:hover {
            transform: scale(1.02);
            box-shadow: var(--boxShadowListingCard);
        }
    }
}

.rootForImage {
    border: solid 1px var(--matterColorNegative);
    border-radius: 4px;
    width: 100%;
    height: 100%;
}

.info {
    /* Layout */
    display: flex;
    flex-direction: column;
    padding: 16px 0 2px 0;
}

.price {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-right: 18px;
}

.priceValue {
    /* Font */
    @apply --marketplaceH3FontStyles;

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);
    display: inline-flex;
    align-items: center;
    margin-left: 8px;

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.mainInfo {
    display: flex;
    flex-direction: column;
}

.title {
    /* Font */
    @apply --marketplaceDefaultFontStyles;
    line-height: 24px;
    color: var(--matterColor);

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.dotSeperator {
    margin: auto 5px;
}

.type {
    text-transform: capitalize;
}

.authorInfo {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);

    padding-top: 4px;
    padding-bottom: 4px;

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}
